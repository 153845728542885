.title {
  font-family: "Bebas Neue", cursive;
}

.address {
  font-size: 1.1rem;
}

.tel {
  font-weight: bold;
  font-size: 1.4rem;
}

.footer {
  color: white;
}

.footer-box {
  background-color: rgba(1, 1, 1, 0.475);
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

.link {
  color: white;
}

.link:hover {
  color: white;
}

.table-center {
  margin: auto;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  border-bottom: 2px solid black;
  color: black !important;
  font-family: "Bebas Neue", cursive;
  font-size: 1.8rem;
}

.nav-pills .nav-link {
  font-family: "Bebas Neue", cursive;
  font-size: 1.8rem;
  color: rgb(117, 117, 117) !important;
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .title-vertical {
    writing-mode: tb-rl;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-vertical-in {
    transform: rotate(180deg);
    text-align: center;
  }

  .hide-on-mobile {
    display: block !important;
  }

  .hide-on-desktop {
    display: none !important;
  }
}

.hide-on-desktop {
  display: block;
}

.hide-on-mobile {
  display: none;
}

.tarif {
  display: flex;
  justify-content: space-between;
}
