body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./../assets/background-min.jpg');
  background-size: 100%;
  background-position: top center;
  background-repeat: repeat-y;
}
